import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiDownload, FiSearch, FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsFilePdf } from "react-icons/bs";
import { BiExport } from "react-icons/bi";
import { FaBan } from "react-icons/fa6";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import oDayJs from "dayjs";

import {
  ScreenContainer,
  Text,
  Input,
  Table,
  ComboBox,
  DateInput,
  PartnerSuggestions,
  Dropdown,
  ConfirmationModal,
  Button,
} from "components";

import { BUTTON_CONFIG } from "config/constants/Button";
import DocumentsLang from "./lang";
import {
  actionDocumentsChangeCurrency,
  actionDocumentsChangeDateFrom,
  actionDocumentsChangeDateTo,
  actionDocumentsChangeCode,
  actionDocumentsChangePage,
  actionDocumentsChangeStatus,
  actionDocumentsClean,
  actionDocumentsGetAll,
  actionDocumentsChangePartner,
  selectDocumentsState,
  actionDocumentsChangeTaxStatus,
  actionDocumentsChangeTaxNro,
  actionDocumentsSendToDGI,
  actionDocumentsExportToCsv,
  actionDocumentsDownload,
  actionDocumentsChangeType,
  actionDocumentsCancel,
  actionDocumentsCleanFilters,
} from "./redux";

import CustomModal from "components/CustomModal";
import { CastDate, CastFloat } from "utils/SharedUtils";
import { CURRENCY_VALUES } from "config/constants/Currency";
import { DOCUMENT_STATUS_CODES, DOCUMENT_STATUS_VALUES, DOCUMENT_TAX_STATUS_VALUES } from "./constants/Documents";
import { actionDocumentTypesGetAll, selectDocumentTypesState } from "pages/documentTypes";

const DocumentsList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();
  const [oReSend, setReSend] = useState(null);
  const [bExport, setExport] = useState(false);
  const [sOpenRow, setOpenRow] = useState(null);
  const [oDownload, setDownload] = useState(null);

  const [oCancel, setCancel] = useState(null);

  const aNewButtons = [
    {
      onClick: () => onNavigate(),
      label: "Nuevo",
    },
    {
      onClick: () => onNavigate({ type: 1 }),
      label: "Venta Contado",
    },
    {
      onClick: () => onNavigate({ type: 2 }),
      label: "Venta Crédito",
    },
  ];

  const aOptionButtons = [
    {
      onClick: () => setExport(true),
      label: "Exportar",
    },
    {
      onClick: () => onNavigate(),
      label: "Nuevo",
    },
    {
      onClick: () => onNavigate({ type: 1 }),
      label: "Venta Contado",
    },
    {
      onClick: () => onNavigate({ type: 2 }),
      label: "Venta Crédito",
    },
  ];

  const {
    aDocuments,
    nPage,
    nCode,
    sTaxNro,
    nPages,
    nRecords,
    bStatus,
    bStatusSend,
    dDateTo,
    dDateFrom,
    oStatus,
    oTaxStatus,
    oCurrency,
    oDataExport,
    sFile,
    oType,
  } = useSelector(selectDocumentsState);
  const { aDocumentTypes } = useSelector(selectDocumentTypesState);

  useEffect(() => {
    oDispatch(actionDocumentsGetAll());
    if (!aDocumentTypes || aDocumentTypes.length === 0) oDispatch(actionDocumentTypesGetAll());
    return () => {
      oDispatch(actionDocumentsCleanFilters());
    };
  }, []);

  useEffect(() => {
    if (sFile) {
      printJS({
        base64: true,
        printable: sFile,
        modalMessage: "Cargando...",
        documentTitle: oDownload.id,
        onPrintDialogClose: () => {
          setDownload(null);
          oDispatch(actionDocumentsClean());
        },
      });
    }
  }, [sFile]);

  useEffect(() => {
    if (bStatus || bStatusSend) {
      oDispatch(actionDocumentsGetAll());
      oDispatch(actionDocumentsClean());
    }
  }, [bStatus, bStatusSend]);

  const onChangeTimeout = (oState, oData) => {
    if (!oData) { return oState; }
    if (oState) { clearTimeout(oState); }
    return oData;
  };

  const [, setTimer] = useReducer(onChangeTimeout, null);

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionDocumentsChangePage(nSelectedPage));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeCode = (sNewCode) => {
    oDispatch(actionDocumentsChangeCode(sNewCode.trim()));
    setTimer(setTimeout(() => {
      oDispatch(actionDocumentsChangePage(1));
      oDispatch(actionDocumentsGetAll());
    }, 800));
  };

  const onChangeTaxNro = (sNewCode) => {
    oDispatch(actionDocumentsChangeTaxNro(sNewCode.trim()));
    setTimer(setTimeout(() => {
      oDispatch(actionDocumentsChangePage(1));
      oDispatch(actionDocumentsGetAll());
    }, 800));
  };

  const onChangePartner = (oPartner) => {
    oDispatch(actionDocumentsChangePartner(oPartner));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeDateFrom = (dDate) => {
    oDispatch(actionDocumentsChangeDateFrom(oDayJs(dDate).toString()));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeDateTo = (dDate) => {
    oDispatch(actionDocumentsChangeDateTo(oDayJs(dDate).toString()));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeCurrency = (oCurrency) => {
    oDispatch(actionDocumentsChangeCurrency(oCurrency));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeType = (oType) => {
    oDispatch(actionDocumentsChangeType(oType));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeStatus = (oStatus) => {
    oDispatch(actionDocumentsChangeStatus(oStatus));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onChangeTaxStatus = (oStatus) => {
    oDispatch(actionDocumentsChangeTaxStatus(oStatus));
    oDispatch(actionDocumentsChangePage(1));
    oDispatch(actionDocumentsGetAll());
  };

  const onDownload = (event, oItem) => {
    event.stopPropagation();
    if (oItem.cae.status) return setDownload(oItem);
  };

  const onConfirmReSend = () => {
    oDispatch(actionDocumentsSendToDGI(oReSend.id));
    setReSend(null);
  };

  const onConfirmDownload = () => {
    oDispatch(actionDocumentsDownload(oDownload.id));
  };

  const onExportToCsv = () => {
    setExport(false);
    oDispatch(actionDocumentsExportToCsv());
  };

  const onReSendOrOpen = (event, oItem) => {
    event.stopPropagation();
    if (oItem.cae?.status) {
      if (sOpenRow === oItem.id)
        return setOpenRow(null);
      return setOpenRow(oItem.id);
    }
    setReSend(oItem);
  };

  const onView = (oItem) => oNavigator(`/documents/${oItem.id}`);
  const onNavigate = (oOptions) => oNavigator("/documents/new", { state: oOptions });

  const onCancel = (e, oItem) => {
    if (e) e.stopPropagation();
    if (!oCancel) return setCancel(oItem);
    oDispatch(actionDocumentsCancel(oCancel.id));
    setCancel(null);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          {DocumentsLang.Labels.listDocuments}
        </Text>
        <div className="gap-2 hidden md:flex">
          <Button
            label="Exportar"
            containerClassName="ml-auto"
            onClick={() => setExport(true)}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<BiExport className="text-white ml-2 text-lg" />}
          />
          <Dropdown
            label="Nuevo"
            items={aNewButtons}
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
          />
        </div>
        <div className="gap-2 flex md:hidden">
          <Dropdown
            label="Opciones"
            items={aOptionButtons}
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={nCode}
            floatLabel={false}
            onChange={onChangeCode}
            placeholder={DocumentsLang.Fields.number}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Desde</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={onChangeDateFrom}
            placeholder={DocumentsLang.Labels.dateFrom}
            containerClassName="rounded-lg overflow-hidden ml-12"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Hasta</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={onChangeDateTo}
            placeholder={DocumentsLang.Labels.dateTo}
            containerClassName="rounded-lg overflow-hidden ml-12"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Estado</span>
          </div>
          <ComboBox
            selected={oStatus}
            size="sm"
            onChange={onChangeStatus}
            emptyLabel={DocumentsLang.Fields.status}
            options={DOCUMENT_STATUS_VALUES}
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Estado</span>
          </div>
          <ComboBox
            size="sm"
            selected={oTaxStatus}
            onChange={onChangeTaxStatus}
            options={DOCUMENT_TAX_STATUS_VALUES}
            emptyLabel={DocumentsLang.Fields.status}
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <ComboBox
            selected={oCurrency}
            onChange={onChangeCurrency}
            emptyLabel={DocumentsLang.Fields.currency}
            options={CURRENCY_VALUES}
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <ComboBox
            size="sm"
            selected={oType}
            onChange={onChangeType}
            options={aDocumentTypes}
            emptyLabel={DocumentsLang.Fields.type}
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-full">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400 z-10" />
          </div>
          <PartnerSuggestions
            onSelect={onChangePartner}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-full">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400 z-10" />
          </div>
          <Input
            withoutClass
            value={sTaxNro}
            floatLabel={false}
            onChange={onChangeTaxNro}
            placeholder={DocumentsLang.Fields.taxOrInternalNumber}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="text-center"/>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                {DocumentsLang.Fields.code}
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                {DocumentsLang.Fields.date}
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3">
                {DocumentsLang.Fields.headline}
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                {DocumentsLang.Fields.typeDoc}
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-right hidden md:table-cell">
                {DocumentsLang.Fields.currency} / {DocumentsLang.Fields.total}
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                {DocumentsLang.Fields.taxStatus}
              </Table.Col>
              <Table.Col scope="col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aDocuments?.length > 0 ? (
              aDocuments.map((oItem, index) => (
                <React.Fragment key={`${oItem.id}_${index}`}>
                  <Table.Row onClick={() => onView(oItem)}>
                    <Table.Col scope="row">
                      {oItem.status !== DOCUMENT_STATUS_CODES.CANCELLED &&
                        <div className="pl-6 flex justify-center cursor-pointer" onClick={e => onCancel(e, oItem)}>
                          <FaBan className="text-lg text-red-400 dark:text-red-400" />
                        </div>}
                    </Table.Col>
                    <Table.Col
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                    >
                      {oItem.code}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                      {CastDate(oItem.date)}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4">
                      {oItem.partner?.name || "-"}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                      {oItem?.type?.name}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 text-right hidden md:table-cell">
                      {oItem?.currency?.code || "S/M"} {CastFloat(oItem.total)}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 flex items-center justify-center gap-3">
                      <div className="flex justify-center cursor-pointer" onClick={e => onReSendOrOpen(e, oItem)}>
                        {oItem.cae?.status
                          ? <BsFilePdf className="text-lg text-green-400 dark:text-green-400" />
                          : <FiSend className="text-lg text-red-400 dark:text-red-400" />
                        }
                      </div>
                    </Table.Col>
                  </Table.Row>
                  {
                    sOpenRow === oItem.id &&
                    <Table.Row>
                      <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                        CAE: {oItem.cae.number}
                      </Table.Col>
                      <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                        <a
                          onClick={(e) => e.stopPropagation()}
                          target="_blank"
                          rel="noreferrer"
                          href={oItem.cae?.link}
                        >
                          Verificar en DGI
                        </a>
                      </Table.Col>
                      <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                        <div className="flex justify-center cursor-pointer" onClick={(e) => onDownload(e, oItem)}>
                          <FiDownload className="text-lg" />
                        </div>
                      </Table.Col>
                    </Table.Row>
                  }
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="4" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        show={!!oReSend}
        title="Re enviar documento"
        onConfirm={onConfirmReSend}
        onClose={() => setReSend(null)}
        question={`Está seguro que quiere re envíar el documento "${oReSend?.code}"`}
        message="Se re enviará el documento a DGI para ser facturado"
      />
      <ConfirmationModal
        show={!!oDownload}
        title="Visualizar documento"
        onConfirm={onConfirmDownload}
        onClose={() => setDownload(null)}
        question={`Está seguro que quiere descargar el documento "${oDownload?.code}"`}
      />
      <ConfirmationModal
        show={bExport}
        onConfirm={onExportToCsv}
        title="Exportar documentos"
        onClose={() => setExport(false)}
        question="Está seguro que quiere exportar la lista de documentos"
        message="Se generará un archivo csv de los documentos que apliquen al filtro actual"
      />
      <CustomModal show={!!oDataExport} title="Archivo cargado correctamente" onClose={() => oDispatch(actionDocumentsClean())}>
        <p className="text-center">
          {
            oDataExport?.records?.length > 0
              ? "Hemos generado y cargado correctamente su archivo, descargarlo desde aquí"
              : "No se encontraron documentos para el filtro aplicado"
          }
        </p>
        <div className="mt-5 text-center md:text-right flex justify-end gap-4">
          <Button
            label="Cerrar"
            type={BUTTON_CONFIG.CLASS.DANGER}
            size={BUTTON_CONFIG.SIZE.SM}
            onClick={() => oDispatch(actionDocumentsClean())}
          />
          {
            oDataExport?.records?.length > 0 &&
            <CSVLink
              className="m-0 p-0"
              data={oDataExport?.records}
              headers={oDataExport?.headers}
              onClick={() => oDispatch(actionDocumentsClean())}
              title={`Documentos ${oDayJs().format("DD-MM-YYYY")}`}
              filename={`Documentos ${oDayJs().format("DD-MM-YYYY")}`}
            >
              <Button
                label="Descargar"
                size={BUTTON_CONFIG.SIZE.SM}
                type={BUTTON_CONFIG.CLASS.SUCCESS}
              />
            </CSVLink>
          }
        </div>
      </CustomModal>
      <ConfirmationModal
        show={!!oCancel}
        onConfirm={onCancel}
        title="Cancelar documento"
        onClose={() => setCancel(null)}
        question={`Está seguro que desea cancelar el documento "${oCancel?.code}"`}
      />
    </ScreenContainer>
  );
};

export default DocumentsList;
